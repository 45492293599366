import { faArrowRight, faLock } from '@fortawesome/free-solid-svg-icons'
import { getCategories, getProducts, getRole } from '../lib/api'
import { getShopConfiguration, isAdminRole } from '../lib/utils'
import { useEffect, useState } from 'react'
import Cart from '../components/Cart'
import Catalog from '../components/Catalog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { NextSeo } from 'next-seo'

// We can show stats, number of orders, products, reviews/stars
export default function Index({ shop, products, categories, query }) {
  const [showSetupLink, setShowSetupLink] = useState(false)
  const [hasRole, setHasRole] = useState(false)

  async function setUp() {
    const about = Boolean(shop.about) && shop.about.length > 1
    const homeWelcome = Boolean(shop.home) && Boolean(shop.home.welcome)

    try {
      const role = await getRole(shop._id)

      if (!(about || homeWelcome)) {
        if (['administrator', 'owner'].includes(role.role)) {
          setShowSetupLink(true)
        }
      }

      setHasRole(isAdminRole(role.role))
    } catch (err) {
      //
    }
  }

  useEffect(() => {
    setUp()
  })

  return (
    <>
      <NextSeo title="Home" />
      <div className="py-lg-5 py-3 bg-light">
        <div className="container">
          <div className="row">
            <div className="col d-block d-lg-none mb-4">
              {Boolean(shop.banner?.url) && (
                <img src={shop.banner?.url} alt={shop.title} className="img-fluid rounded" />
              )}
            </div>

            <div className="col-lg-5 col-12">
              <h1 className="h1">{shop.home?.welcome || `Welcome to ${shop.title}`}</h1>

              <p className="text-muted fs-6 py-3">
                {shop.home?.intro || 'This shop is still under preparation. Please come back later'}
              </p>

              <p>
                <Link href="/products">
                  <a className="btn btn-primary">
                    Check us out &nbsp; <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                </Link>
              </p>

              {showSetupLink && (
                <div className="mb-3">
                  <p>
                    If you are the owner of this site, please complete your site setup to make it
                    ready for your customers.
                  </p>
                  <Link href="/setup">
                    <a className="btn btn-secondary text-white me-4">Complete setup</a>
                  </Link>
                </div>
              )}
              {hasRole && (
                <div>
                  <Link href="/admin">
                    <a className="btn btn-secondary text-white me-4">
                      <FontAwesomeIcon icon={faLock} /> &nbsp; Go to Admin Dashboard
                    </a>
                  </Link>
                  <br />
                  <small className="text-muted">Only you can see this.</small>
                </div>
              )}
            </div>

            <div className="col d-none d-lg-block">
              {Boolean(shop.banner?.url) && (
                <img src={shop.banner?.url} alt={shop.title} className="img-fluid" />
              )}
            </div>
          </div>
        </div>
      </div>

      <Catalog
        initialProducts={products}
        categories={categories}
        query={{ ...query, available: true, shop: shop._id }}
      />

      <Cart />
    </>
  )
}

export async function getServerSideProps({ query, req }) {
  const config = await getShopConfiguration(req.locals.config.shop)
  const shopId = config.props.shop._id

  return {
    props: {
      categories: await getCategories(shopId),
      products: (await getProducts({ ...query, available: true, shop: shopId })).data,
      ...config.props,
      query,
    },
  }
}
